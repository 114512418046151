export const getConversion = (media, type = 'large') => {
    try {
        if (media.generated_conversions === undefined || media.generated_conversions === null ||
            JSON.stringify(media.generated_conversions) === '{}' || media.generated_conversions === '' ||
            media.generated_conversions[type] === undefined) {
            return media.original_url;
        }
    
        if (media.generated_conversions[type] === true) {
            var parts = media.original_url.split('/');
            parts.pop()
            var new_url = parts.join('/')
            new_url += '/conversions/' + type + '.jpg'
            return new_url;
        } else {
            return media.original_url;
        }
    } catch (error) {
        if (media.original_url === undefined) {
            return '';
        }
        return media.original_url;
    }
    
}
