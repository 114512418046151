import React from 'react'
import { Link } from 'gatsby'
import { format } from 'date-fns'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import {getConversion} from '../../api/images';
const GalleryCard = ({ post }) => {
    const { slug, title, media } = post
    return (
        <div className='mb-3 col-lg-4 col-md-6'>
            <div className='single-blog-post bg-fffbf5 h-100'>
                <div className='post-image'>
                    <Link to={`/gallery-details?slug=${slug}`} >
                    {media.length > 0 ?
                                <>
                                <div className='article-image'>
                                    <img src={getConversion(media[0])} alt={title} />
                                </div>
                                </>
                                : null
                            }
                    </Link>
                </div>
                <div className='post-content'>
                    <h5>
                        <Link to={`/gallery-details?slug=${slug}`}>
                            {title}
                        </Link>
                    </h5>
                </div>
            </div>
        </div>
    )
}

export default GalleryCard